<template>
  <div class="UserHome">
    
    <div class="bottomDiv1" style="height: auto; margin-bottom: 15px;" >
      <div class="index_title" style="border-bottom: none">设备管理</div>
      <div class="list1" style="font-size: 16px;">
        <router-link class="list2" to="/mobile_index/device_manage"><p>充电站</p><p>{{ total }}</p></router-link>
        <router-link class="list2" to="/mobile_index/charg_manage"><p>设备</p><p>{{ total1 }}</p></router-link>
      </div>
      
    </div>
    <div class="bottomDiv1" style="height: auto;">
      <div class="index_title" style="border-bottom: none">充电站列表</div>
      <div>
          <el-form  style="width:50vw; margin-left: 5vw;">
            <el-select v-model="FormData.type_id" placeholder="请选择充电车型">
              <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
            </el-select>
          </el-form>
          <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
            <el-form-item style="width:50vw; margin-left: 5vw; margin-top: 2px;">
              <el-input
                v-model="FormData.station_number"
                placeholder="请输入充电站编号"
              ></el-input>
            </el-form-item>
        
            <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
        </el-form>
      </div>
      <!-- <div v-for="item in tableData " :key="item">
        <div class="orderlist">
          <p>{{item.order_id}}</p>
        </div>
      </div> -->
      <div class="tableDiv">
          <el-table
        :data="tableData"
            border
            v-loading="loading"
            style="
              width: 90%; 
              margin: 20px auto;
              
              overflow-y: auto;
            "
        >
        <el-table-column label="编号" prop="station_number"/>
            <!-- <el-table-column label="手机号" prop="phone" />
            <el-table-column label="设备编号" prop="device_id" />
            <el-table-column label="充电站名称" prop="station_name" />
            <el-table-column label="充电类型" prop="type_id">
              <template #default="scope">
                <span v-if="scope.row.type_id == 2">电动车</span>
                <span v-if="scope.row.type_id == 4">电动汽车</span>
              </template>
            </el-table-column>
            <el-table-column label="充电端口" prop="socket" width="80" /> -->
            <el-table-column
            label="详情"
            align="center"
          >
            <template #default="scope" >
              <div style="float: left;">
                <div class="BtnSearch" @click="todetal(scope.$index, scope.row)">详情</div>
              </div>
              
            </template>
          </el-table-column>
            <el-table-column
            label="操作"
            align="center"
            width="120"
          >
            <template #default="scope" >
              <div>
                <div v-show="scope.row.is_return == 0" class="BtnSearch1 BtnSearch" @click="handleOp(scope.$index, scope.row)">开启退费</div>
                <div v-show="scope.row.is_return == 1" class="BtnSearch1 btnclose" @click="handleCl(scope.$index, scope.row)">关闭退费</div>
              </div>
              
            </template>
          </el-table-column>
           
          </el-table>
          <el-dialog
            v-model="Dialog.editLevy"
            title="充电站详情"
            width="80%"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                label-position="right"
                label-width="100px"
                style="font-size: 16px;"
              >
                <p>充电站编号: {{ Dialog.DialogData.station_number }}</p>
                <p>充电站名称: {{ Dialog.DialogData.station_name }}</p>
                <p>省: {{ Dialog.DialogData.province }}</p>
                <p>市: {{ Dialog.DialogData.city }}</p>
                <p>县/区: {{ Dialog.DialogData.county }}</p>
                <p>详细地址: {{ Dialog.DialogData.station_addr }}{{ Dialog.DialogData.community }}</p>
                <p>计费模板: {{ Dialog.DialogData.price_name }}</p>
                <p>充电类型: {{ Dialog.DialogData.type_id == '2'?'电动车':'电动汽车' }}</p>
              
              
              </el-form>
            </div>
          </el-dialog>
        </div>
    </div>
  </div>
</template>
<style scoped>
/* 标题文字样式 */

.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 0.5rem;
  font-size: 18px;
}

.BtnSearch1{
  float: left;
  width: 90px;
  margin: 0 4px;
}
.formDiv1 {
  margin-top: -20px;
  padding: 2%;
}
.formDiv1  p{

  /* height: 30px; */
  line-height: 30px;
  /* border-bottom: 1px solid lightblue; */
  /* margin: 0.2rem; */
}
.list1{
  padding: 5px;

}
.list2{
  color: black;
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid lightblue;
}
.btnclose{
  background-color: #036363;
  color: white;
  /* width: 55px; */
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
}
</style>

<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_station,return_switch,sel_device } from "@/request/api";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from '../../assets/json/address'
export default {
  name: "UserRecord",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        operator_id : sessionStorage.getItem('userID'),
      },
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      total:'',
      total1:'',
      tableData: [
        {
          user_id:11,
          contact_way:13256561235,
          wx_id:"001",
          mailbox:"CD001"
        }
      ],
      loading: false,
      provinceData: ProvinceCityCountry.address,
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

    });
    const searchBtn = () => {
      
      data.loading = true;
      sel_station(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getList = () => {
      
      data.loading = true;
      sel_station(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });

      sel_device(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.total1 = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

      const todetal = (index,row) => {

        data.Dialog.editLevy = true;
        data.Dialog.DialogData = row;
    }
  
    const handleOp = (index,row)=>{
      const dataa = {
        id:row.id,
        is_return:1
      }
      return_switch(dataa).then((res)=>{
        if (res) {
          ElMessage.success("开启成功");
          getList()
        } else {
          ElMessage.error("开启失败");
        }
      })
      
    }
    const handleCl = (index,row)=>{
      const dataa = {
        id:row.id,
        is_return:0
      }
      return_switch(dataa).then((res)=>{
        if (res) {
          ElMessage.success("关闭成功");
          getList()
        } else {
          ElMessage.error("关闭失败");
        }
      })
    }
 
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      todetal,
      handleOp,
      handleCl
    };
  },
}
</script>
